import React from "react";
import { TextField, Box, Grid, Button, Paper } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "dark" && {
      background: {
        default: "#011e33",
      },
    }),
    ...(mode === "light" && {
      background: {
        paper: "#dcedfa",
      },
    }),
  },
});

function calculate(height, weight) {
  const kgToLbs = 0.45359237;
  const inToM = 0.0254;
  const ret = (weight * kgToLbs) / ((height * inToM) ^ 2);

  return Math.round(ret * 10) / 10;
}

function Calculator() {
  const [height, setHeight] = React.useState();
  const [weight, setWeight] = React.useState();
  const [results, setResults] = React.useState("N/A");
  const [category, setCategory] = React.useState();
  const [color, setColor] = React.useState("red");

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = createTheme(
    getDesignTokens(prefersDarkMode ? "dark" : "light")
  );

  const handleHeight = (event) => {
    setHeight(event.target.value);
  };

  const handleWeight = (event) => {
    setWeight(event.target.value);
  };

  const handleResults = () => {
    if (height && weight) {
      setResults(calculate(height, weight));
    } else {
      alert("please enter both height and weight");
    }
  };

  React.useEffect(() => {
    if (results < 19) {
      setCategory("underweight");
      setColor("blue");
    }
    if (results >= 19 && results < 25) {
      setCategory("normal");
      setColor("green");
    }
    if (results >= 25 && results < 30) {
      setCategory("overweight");
      setColor("yellow");
    }
    if (results >= 30 && results < 39) {
      setCategory("obese");
      setColor("orange");
    }
    if (results >= 39) {
      setCategory("extremely obese");
      setColor("red");
    }
  }, [results]);

  return (
    
    <ThemeProvider theme={theme}>
      
      <div className="calculator">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
          sx={{
            bgcolor: "background.default",
            color: "text.primary",
          }}
        >
          <h1 style={{ textTransform: "uppercase", marginBottom: "75px" }}> BMI Calculator </h1>
          <Paper elevation={3} sx={{
            p: "15px",
            textAlign: "center"
          }}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="height"
                label="height in inches"
                value={height}
                onChange={handleHeight}
                inputProps={{
                  type: "number",
                }}
              />
              <TextField
                id="weight"
                label="weight in pounds"
                value={weight}
                onChange={handleWeight}
                inputProps={{
                  type: "number",
                }}
              />
              <Button
                variant="contained"
                onClick={handleResults}
                sx={{ p: "15px"}}
                size="large"
              >
                calculate
              </Button>
            </Box>
          </Paper>
          <h1>BMI = {results}</h1>
          {category && (
            <h1 style={{ color: color, textTransform: "uppercase" }}>
              {category}
            </h1>
          )}
        </Grid>
      </div>
    </ThemeProvider>
  );
}

export default Calculator;
